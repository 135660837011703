import {CheckIcon, LoaderIcon, TriangleAlert} from "lucide-react";
import * as React from 'react'
import { useSpinDelay } from 'spin-delay'
import { cn } from '#app/utils/misc.tsx'
import { Button, type ButtonProps } from './button.tsx'
import {
	Tooltip,
	TooltipContent,
	TooltipProvider,
	TooltipTrigger,
} from './tooltip.tsx'

export const StatusButton = React.forwardRef<
	HTMLButtonElement,
	ButtonProps & {
		status: 'pending' | 'success' | 'error' | 'idle'
		message?: string | null
		spinDelay?: Parameters<typeof useSpinDelay>[1],
		skipSuccess?: boolean
	}
>(({ message, status, className, children, spinDelay, skipSuccess = true, ...props }, ref) => {
	const delayedPending = useSpinDelay(status === 'pending', {
		delay: 400,
		minDuration: 300,
		...spinDelay,
	})
	const companion = {
		pending: delayedPending ? (
			<div className="inline-flex h-4 w-4 items-center justify-center">
				<LoaderIcon className="animate-spin" />
			</div>
		) : null,
		success: !skipSuccess ? (
			<div className="inline-flex h-4 w-4 items-center justify-center">
				<CheckIcon />
			</div>
		) : null,
		error: (
			<div className="inline-flex h-4 w-4 items-center justify-center">
				<TriangleAlert />
			</div>
		),
		idle: null,
	}[status]

	return (
		<Button
			ref={ref}
			className={cn('flex justify-center gap-2', className)}
			disabled={status === 'pending'}
			{...props}
		>
			{message ? (
				<TooltipProvider>
					<Tooltip>
						<TooltipTrigger>{companion}</TooltipTrigger>
						<TooltipContent>{message}</TooltipContent>
					</Tooltip>
				</TooltipProvider>
			) : (
				companion
			)}
			<div className="flex justify-center items-center">{children}</div>
		</Button>
	)
})
StatusButton.displayName = 'Button'
